import { DeliveryStatusFilter } from '@nexploretechnology/concreting-core-client';
import moment from 'moment';
import { createContext, useMemo, useCallback, useEffect, useReducer } from 'react';
import useLocalStorage from 'src/app-react/hooks/useLocalStorage';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { useSearchParamsState } from 'src/app-react/hooks/useSearchParamsState';

export type DeliveryDashboardFilter = DeliveryStatusFilter & { period?: string; projectId?: string };

export interface DeliveryDashboardContextValue {
    filter?: DeliveryDashboardFilter;
    setFilter?: (filter: DeliveryDashboardFilter) => void;
}

const DeliveryDashboardContext = createContext<DeliveryDashboardContextValue>({});

function DeliveryDashboardProvider({ children }: { children: unknown }) {
    const { projectId } = useProjectState();
    const [reloaded, reloadDeliveryDashboardPage] = useReducer((x) => x + 1, 0);
    const [localStorageDeliveryFilter, setLocalStorageDeliveryFilter] = useLocalStorage('deliveryFilter');
    const [filter, _setFilter] = useSearchParamsState<DeliveryDashboardFilter>(
        'filter',
        localStorageDeliveryFilter?.find((x: DeliveryDashboardFilter) => x.projectId === projectId)
    );

    const setFilter = useCallback(
        (newFilter: DeliveryDashboardFilter) => {
            _setFilter((_prev: any) => {
                const newFilterWithProject = { projectId, ...newFilter };
                setLocalStorageDeliveryFilter([
                    newFilterWithProject,
                    ...(localStorageDeliveryFilter?.filter((x: DeliveryDashboardFilter) => x.projectId !== projectId) ||
                        [])
                ]);
                return newFilterWithProject;
            });
        },
        [_setFilter, projectId, localStorageDeliveryFilter, setLocalStorageDeliveryFilter]
    );

    useEffect(() => {
        const deliveryDashboardDefaultFilter = {
            projectId,
            period: 'today',
            periodOfConcreting: {
                startDate: moment().startOf('day').toDate(),
                endDate: moment().endOf('day').toDate()
            }
        };
        if (!filter) {
            setFilter(deliveryDashboardDefaultFilter);
        }
    }, [filter, setFilter, projectId]);

    const value = useMemo(
        () => ({ filter, setFilter, reloaded, reloadDeliveryDashboardPage }),
        [filter, setFilter, reloaded, reloadDeliveryDashboardPage]
    );
    return <DeliveryDashboardContext.Provider value={value}>{children}</DeliveryDashboardContext.Provider>;
}

export { DeliveryDashboardContext, DeliveryDashboardProvider };
